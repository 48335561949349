import React from 'react';
import { useTranslation } from 'react-i18next';

export const SearchPage = () => {
  const { t } = useTranslation();

  return <h2>{t('aes-alexa-enterprise-services')}</h2>;
};

export default SearchPage;
