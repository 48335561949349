import React from 'react';
import { RouteObject } from 'react-router-dom';
import SiteDown from '@amzn/aes-maestro-website-core/dist/components/patterns/Errors/SiteDown';
import PageNotFound from '@amzn/aes-maestro-website-core/dist/components/patterns/PageNotFound/PageNotFound';
import SearchPage from 'src/components/pages/search/SearchPage';

// UI routes for website.
export const routes: RouteObject[] = [
  {
    path: '/',
    Component: SearchPage,
    errorElement: <SiteDown />,
  },
  {
    path: '*',
    Component: PageNotFound,
  },
];
