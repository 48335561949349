import { Amplify, Auth } from 'aws-amplify';
import { IDP, AMPLIFY_API_NAME } from 'src/constants/constants';
import { getApiConfig } from 'src/utils/api-config';

const apiConfig = getApiConfig();
const idp: IDP = IDP.amazonFederate;
const authConfig = apiConfig.authConfig[idp];

export class AmplifyAuthProvider {
  /**
   * Init Amplify Auth+API Config.
   */
  static initialize(): void {
    const { region, userPoolId, userPoolWebClientId, hostedUIDomain, identityPoolId } = authConfig || {};

    Amplify.configure({
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        identityPoolId,
        oauth: {
          domain: hostedUIDomain,
          redirectSignIn: window.location.origin,
          redirectSignOut: window.location.origin,
          responseType: 'code',
        },
      },
      API: {
        endpoints: [
          {
            name: AMPLIFY_API_NAME.AES_BE,
            endpoint: apiConfig.aesBEURL,
            region: apiConfig.aesBERegion,
            custom_header: async () => {
              return { 'aes-jwt-token': (await Auth.currentSession()).getIdToken().getJwtToken() };
            },
          },
        ],
      },
    });
  }

  /**
   * Authenticate user using federated signIn.
   */
  public static async authenticateUser() {
    const providerName = authConfig?.providerName || 'AmazonFederate';

    return await Auth.currentAuthenticatedUser()
      .then((user: any) => {
        return Promise.resolve(user);
      })
      .catch(() => {
        // Invoke Cognito Federated auth flow.
        // Then redirect back to homepage once sign in successfully.
        Auth.federatedSignIn({ customProvider: providerName });

        return Promise.resolve(null);
      });
  }
}
